import immutable from 'object-path-immutable'
import {LOGIN, LOGOUT} from './actions'
import {SUCCESS} from '../utils/actions'

export const initialState = {
  token: null
}

function authReducer(state = initialState, action) {
  const {type, status, payload} = action
  switch (type) {
    case LOGIN:
      return status === SUCCESS
        ? immutable.set(state, 'token', payload)
        : state
    case LOGOUT:
      return immutable.set(state, 'token', null)
    default:
      return state
  }
}

export default authReducer
