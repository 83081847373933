import {CHANGE_ORDER, TOGGLE_LEVEL} from './actions'
import immutable from 'object-path-immutable'

export const initialState = {
  status: {
    campaigns: true, groups: true, phrases: true,
    ads: false, sources: false
  },
  order: ['campaigns', 'groups', 'phrases', 'ads', 'sources']
}

const toggle = v => !v

function hierarchyReducer(state = initialState, action) {
  const {type, payload} = action
  switch (type) {
    case CHANGE_ORDER:
      return immutable.set(state, 'order', payload)
    case TOGGLE_LEVEL:
      return immutable.update(state, ['status', payload], toggle)
    default:
      return state
  }
}

export default hierarchyReducer
