import React, {Component} from 'react'

import {PaginationContainer} from 'containers'

import style from './styles/Footer.module.scss'

class Footer extends Component {
  render() {
    return (
      <div className={style.normal}>
        <PaginationContainer />
      </div>
    )
  }
}

export default Footer
