import React, {Component} from 'react'
import {Checkbox} from 'components/utility'

import style from './styles/CellHeaderName.module.scss'

class CellHeaderName extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: false
    }
  }

  handleToggle = (id, status) => {
    this.setState({[id]: status})
  }

  render() {
    const {checked} = this.state
    return (
      <td className={style.normal}>
        <div className={style.cell}>
          <Checkbox id="checked" isActive={checked} onToggle={this.handleToggle} />
          <span className={style.text}>показать скрытые</span>
        </div>
      </td>
    )
  }
}

export default CellHeaderName
