import React, {Component} from 'react'

import {Icon} from 'components/utility'

import style from './styles/TableSearch.module.scss'

class TableSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: ''
    }
  }

  handleChange = event => {
    const {value} = event.target
    this.setState({value})
  }

  handleSubmit = event => {
    event.preventDefault()

    const {onSubmit} = this.props
    const {value} = this.state

    if (typeof onSubmit === 'function') {
      onSubmit(value)
    }
  }

  render() {
    const {value} = this.state
    return (
      <div className={style.normal}>
        <h3 className={style.title}>Сквозная аналитика версия 1.2.2</h3>
        <p className={style.desc}>
          <Icon className={style.descIcon} icon="security" />
          <span className={style.descText}>Теперь вы можете импортировать отчеты напрямую в&nbsp;</span>
          <button className={style.descButton}><Icon className={style.descButtonIcon} icon="excel" /> Excel Sheet</button>
        </p>
        <form className={style.form} onSubmit={this.handleSubmit}>
          <button className={style.formButton}><Icon icon="search" /></button>
          <input
            className={style.formInput}
            type="text"
            value={value}
            placeholder="Найдите любые данные тут"
            onChange={this.handleChange}
          />
        </form>
      </div>
    )
  }
}

export default TableSearch
