import React, {Component} from 'react'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'

import {HierarchyItem} from '../'
import {Icon} from 'components/utility'
import {hierarchyTitles} from 'data/analytics'

import style from './styles/Hierarchy.module.scss'

class Hierarchy extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  saveDropdown = ref => this.dropdown = ref
  saveButton = ref => this.button = ref

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = event => {
    if (this.dropdown && !this.dropdown.contains(event.target) && event.target !== this.button) {
      this.setState({isOpen: false})
    }
  }

  handleToggle = (event) => {
    event.preventDefault()

    this.setState(prev => ({isOpen: !prev.isOpen}))
  }

  handleCheck = (id, isActive) => {
    const {onToggle} = this.props
    if (typeof onToggle === 'function') {
      onToggle(id)
    }
  }

  handleDragEnd = result => {
    const {source, destination} = result

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const {order, onOrder} = this.props
    const itemIndex = source.index
    const placeIndex = destination.index
    const reducedLevels = order.filter((l, i) => i !== itemIndex)
    reducedLevels.splice(placeIndex, 0, order[itemIndex])

    if (typeof onOrder === 'function') {
      onOrder(reducedLevels)
    }
  }

  render() {
    const {order, status} = this.props
    const {isOpen} = this.state
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <div className={isOpen ? style.opened : style.normal}>
          <div ref={this.saveDropdown} className={style.dropdown}>
            <Droppable droppableId="hierarchy">
              {(provided) => (
                <ul
                  ref={provided.innerRef}
                  className={style.list}
                  {...provided.droppableProps}
                >
                  {order.map((l, i) => (
                    <HierarchyItem
                      key={l}
                      id={l}
                      status={status[l]}
                      title={hierarchyTitles[l]}
                      index={i}
                      onToggle={this.handleCheck}
                    />
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
            <button className={style.close} onClick={this.handleToggle}>
              <Icon className={style.closeIcon} icon="close-big" />
            </button>
          </div>
          <button ref={this.saveButton} className={style.button} onClick={this.handleToggle}>
            <Icon className={style.buttonIcon} icon="refresh" />
            <span className={style.buttonLabel}>изменить иерархию</span>
          </button>
        </div>
      </DragDropContext>
    )
  }
}

export default Hierarchy
