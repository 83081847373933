import React, {Component} from 'react'

import {Icon} from 'components/utility'

import style from './styles/CellAdj.module.scss'

class CellAdj extends Component {
  roiAdjustment = (roi, troi) => roi / troi - 1
  leadAdjustment = (cost, tcost) => tcost / cost - 1

  roiRaiseAdjustment = () => {
    const {row, params} = this.props
    const roi = row.attributes.ROI - 100
    const {troi} = params
    return roi > 70
      ? Math.max(this.roiAdjustment(roi, troi), 0)
      : 0
  }
  
  leadDecreaseAdjustment = () => {
    const {row, params} = this.props
    const {cost} = row.attributes
    const roi = row.attributes.ROI - 100
    const {tcost} = params
    return roi < 0 && cost > tcost
      ? Math.min(this.leadAdjustment(cost, tcost), 0)
      : 0
  }

  limitedAdjustment = () => {
    const {params} = this.props
    const {max} = params.rate
    const adjustment = this.calcGenericAdjustment()
    return Math.abs(adjustment) > max
      ? adjustment < 0 ? 0 - max : max
      : adjustment
  }

  cpcAdjustment = (adjustment) => {
    const {row, params} = this.props
    const {CPC} = row.attributes
    const {min, max} = params.rate
    const endCPC = CPC + CPC * adjustment
    return endCPC < min ?
      min - CPC : endCPC > max ?
        max - CPC : CPC * adjustment
  }

  roundAdjustment = adj => Math.round(adj * 100) / 100

  calcGenericAdjustment() {
    const {column} = this.props
    switch(column.id) {
      case 'adjustmentA':
        return this.roiRaiseAdjustment()
      case 'adjustmentB':
        return this.leadDecreaseAdjustment()
      default:
        return 0
    }
  }

  genericAdjustment() {
    const {row, params} = this.props
    const {clicks} = row.attributes
    const clicksLimit = params.clicks
    const limited = this.limitedAdjustment()
    const result = this.roundAdjustment(this.cpcAdjustment(limited))
    const isActive = clicks >= clicksLimit
    const value = !isActive
      ? '—'
      : result === 0
        ? '—'
        : result > 0
          ? `+${result}`
          : result
    return value
  }

  getIcon = () => {
    const {id} = this.props.column
    return id === 'adjustmentA'
      ? <Icon className={style.green} icon='arrow-up' />
      : <Icon className={style.red} icon='arrow-down' />
  }

  render() {
    const value = this.genericAdjustment()
    return (
      <td className={style.normal}>
        {value !== '—' && this.getIcon()}
        {value}
        {value !== '—' && <span className={style.dimension}>руб.</span>}
      </td>
    )
  }
}

export default CellAdj
