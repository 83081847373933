import React, {Component} from 'react'
import style from './styles/Checkbox.module.scss'

class Checkbox extends Component {
  handleToggle = (event) => {
    event.preventDefault()

    const {id, isActive, onToggle} = this.props

    if (typeof onToggle === 'function') {
      onToggle(id, !isActive)
    }
  }

  getClass() {
    const {isActive, className: mixin} = this.props
    return `${mixin ? mixin : ''} ${isActive ? style.active : style.normal}`
  }

  render() {
    return (
      <button className={this.getClass()} onClick={this.handleToggle}>
        <span className={style.circle}></span>
      </button>
    )
  }
}

export default Checkbox
