import {connect} from 'react-redux'

import {List} from './'

const mapStateToProps = (state, {id}) => ({
  title: 'Заголовок',
  list: [
    {
      id: 'mmorpg',
      title: 'MMORPG',
      params: [
        {
          id: 'clicks',
          value: '42',
          icon: 'hand-click'
        },
        {
          id: 'count',
          value: '42',
          icon: 'description'
        }
      ]
    },
    {
      id: 'pvpzarub',
      title: 'PVP-заруб',
      params: [
        {
          id: 'clicks',
          value: '42',
          icon: 'hand-click'
        },
        {
          id: 'count',
          value: '42',
          icon: 'description'
        }
      ]
    },
    {
      id: 'slashers',
      title: 'Слэшеры',
      params: [
        {
          id: 'clicks',
          value: '42',
          icon: 'hand-click'
        },
        {
          id: 'count',
          value: '42',
          icon: 'description'
        }
      ]
    },
    {
      id: 'shooters',
      title: 'Шутеры',
      params: [
        {
          id: 'clicks',
          value: '42',
          icon: 'hand-click'
        },
        {
          id: 'count',
          value: '42',
          icon: 'description'
        }
      ]
    },
    {
      id: 'arcades',
      title: 'Аркады',
      params: [
        {
          id: 'clicks',
          value: '42',
          icon: 'hand-click'
        },
        {
          id: 'count',
          value: '42',
          icon: 'description'
        }
      ]
    }
  ]
})

export default connect(
  mapStateToProps,
  null
)(List)
