import immutable from 'object-path-immutable'
import {columnsOrder} from './columns'
import {CHANGE_ORDER, TOGGLE_COLUMN} from './actions'

export const initialState = {
  status: prepareStatus(columnsOrder, ['name', 'CPC', 'ROI']),
  order: columnsOrder
}

const toggle = v => !v

function columnsReducer(state = initialState, action) {
  const {type, payload} = action
  switch (type) {
    case CHANGE_ORDER:
      return immutable.set(state, 'order', payload)
    case TOGGLE_COLUMN:
      return immutable.update(state, ['status', payload], toggle)
    default:
      return state
  }
}

function prepareStatus(all, active) {
  return all.reduce((result, id) => (
    {...result, [id]: active.indexOf(id) !== -1}
  ), {})
}

export default columnsReducer
