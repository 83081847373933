import {CHANGE, RESET} from './actions'
import immutable from 'object-path-immutable'

const from = new Date()
from.setHours(0,0,0,0)
const to = new Date()
to.setHours(0,0,0,0)

const initialState = {
  date: {
    from,
    to
  }
}

function filtersReducer(state = initialState, action) {
  const {type, payload} = action
  switch (type) {
    case CHANGE:
      return immutable.set(state, payload.name, payload.value)
    case RESET:
      return payload !== undefined
        ? immutable.set(state, payload, initialState[payload])
        : initialState
    default:
      return state
  }
}

export default filtersReducer
