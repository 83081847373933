import {fetchMetrics as fetchMetricsData} from './responses/fetchMetricsStub'
import {getQuery} from '../utils/api'
import {delay, map, mergeMap} from 'rxjs/operators'
import {of, from} from 'rxjs'
import {hash32} from '../utils/hash'

const fetchTable = getQuery('metrics.table')
export const fetchAlgorithms = getQuery('metrics.algorithms', ({id, ...params}) => ({
  routeParams: {id},
  queryParams: params
}))

const idName = {
  campaigns: 'camp_id',
  groups: 'group_id',
  ads: 'ad_id',
  phrases: 'phrase',
  sources: 'source'
}

const titleName = {
  campaigns: 'camp_name',
  groups: 'group_name',
  ads: 'ad_name',
  phrases: 'term',
  sources: 'source'
}

function withIdName(hierarchy) {
  return (id, index) => `${idName[hierarchy[index]]}:${id}`
}

function makeParams(query) {
  return (request) => {
    const base = {
      target: request.level,
      sortBy: query.sorting.sortBy,
      sortOrder: query.sorting.sortOrder,
      client: query.client,
      page: query.page,
      limit: query.limit,
      date_from: query.date_from,
      date_to: query.date_to,
    }
    return request.parents
      ? {...request, ...query, params: {...base, parents: request.parents.join(',')}}
      : {...request, ...query, params: base}
  }
}

function getTreeRequests(tree, hierarchy) {
  const sublevels = tree.reduce((sublevels, path) => {
    const parents = path.map(withIdName(hierarchy)).join(';')
    const level = path.length - 1
    if (sublevels[level] !== undefined) {
      sublevels[level].push(parents)
    } else {
      sublevels[level] = [parents]
    }
    return sublevels
  }, Array(5))
  return sublevels.map((parents, index) => (parents ? {
    level: hierarchy[index + 1], parents
  } : null)).filter(v => v !== null)
}

export function fetchMetrics(query) {
  const {tree, hierarchy, isFullUpdate} = query
  let requests = []
  if (isFullUpdate) {
    requests.push({level: hierarchy[0]})
  }
  if (tree.length > 0) {
    requests = requests.concat(getTreeRequests(tree, hierarchy))
  }
  return from(requests.map(makeParams(query))).pipe(
    mergeMap(req => fetchTable(req.params, query.token).pipe(
      map(decodeResponse(req))
    ))
  )
}

function decodeResponse(request) {
  return (response) => {
    const mainData = response.data.reduce(decodeItem(request), {
      data: [], included: []
    })
    return {
      meta: {
        hierarchy: request.hierarchy,
        isFullUpdate: request.isFullUpdate,
        tree: request.tree
      },
      parent: request.parent,
      page: response.page,
      pages: response.pages,
      ...mainData
    }
  }
}

function decodeItem(request) {
  return (result, item) => {
    const data = extractMetricsFromItem(item, request)
    const source = extractSourceFromItem(item, request, data.id)
    return {
      data: result.data.concat(data),
      included: result.included.concat(source)
    }
  }
}

function extractSourceFromItem(item, request, metrics) {
  const id = getItemId(item, request)
  return {
    id,
    type: request.level,
    attributes: {
      title: item[titleName[request.level]]
    },
    relationships: {metrics}
  }
}

function extractMetricsFromItem(item, request) {
  const hierarchy = getItemHierarchy(item, request)
  const isOpen = hierarchy.length === request.hierarchy.length
    ? null : {isOpen: false}
  return {
    id: hash32(hierarchy.join('-')),
    type: 'metrics',
    meta: {hierarchy, ...isOpen},
    attributes: item,
    relationships: {
      parent: hash32(hierarchy.slice(0, -1).join('-')),
      children: [],
      source: {
        type: request.level,
        id: getItemId(item, request)
      }
    }
  }
}

function getItemId(item, request) {
  return item[idName[request.level]]
}

function getIdName(level) {
  return idName[level]
}

function mapHierarchyIds(parents) {
  return (selector) => parents[selector]
}

function getItemHierarchy(item, request) {
  const level = request.hierarchy.indexOf(request.level)
  const parents = request.hierarchy.slice(0, level)
    .map(getIdName)
    .map(mapHierarchyIds(item.parents || {}))
  return parents.concat(getItemId(item, request))
}
