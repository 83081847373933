import immutable from 'object-path-immutable'
import {TOGGLE_ROW, FETCH_ROWS, CHANGE_PAGE, CHANGE_LIMIT} from './actions'
import {SUCCESS} from '../utils/actions'
import {normalizeMetrics} from './entities'

export const initialState = {
  isFetching: false,
  metrics: {},
  rows: [],
  page: 1,
  total: 1,
  limit: 15,
  isFetching: false
}

function toggleRow(rowMeta) {
  if (typeof rowMeta.isOpen !== 'undefined') {
    return immutable.merge(rowMeta, [], {
      isOpen: !rowMeta.isOpen,
      isFetching: !rowMeta.isOpen
    })
  }
  return rowMeta
}

function fetchRowsReducer(state, action) {
  const {type, status, payload} = action
  if (status === SUCCESS) {
    const data = immutable.merge(state, [], normalizeMetrics(payload))
    return payload.meta.isFullUpdate
      ? {...data, page: payload.page, total: payload.pages, isFetching: false}
      : data
  }
  return state
}

function metricsReducer(state = initialState, action) {
  const {type, status, payload} = action
  switch (type) {
    case FETCH_ROWS:
      return fetchRowsReducer(state, action)
    case TOGGLE_ROW:
      return immutable.update(state, ['metrics', payload, 'meta'], toggleRow)
    case CHANGE_PAGE:
      return immutable.merge(state, [], {
        page: Math.min(payload, state.total),
        isFetching: true
      })
    case CHANGE_LIMIT:
      return immutable.merge(state, [], {
        limit: payload,
        isFetching: true
      })
    default:
      return state
  }
}

export default metricsReducer
