import React, {Component} from 'react'

import style from './styles/TableHead.module.scss'

class TableHead extends Component {
  render() {
    return (
      <div className={style.normal}></div>
    )
  }
}

export default TableHead
