import {makeActionCreator} from '../utils/actions'

export const TOGGLE_ROW = 'metrics/toggleRow'
export const FETCH_ROWS = 'metrics/fetchRows'
export const CHANGE_PAGE = 'metrics/changePage'
export const CHANGE_LIMIT = 'metrics/changeLimit'

export const changePage = makeActionCreator(CHANGE_PAGE)
export const changeLimit = makeActionCreator(CHANGE_LIMIT)

/**
 * @function toggleRow
 * @param {string} id - идентификатор строки, которую нужно открыть/закрыть
 * @returns {Object} экшен для тоггла состояния строки таблички
 */

export const toggleRow = makeActionCreator(TOGGLE_ROW)

/**
 * @function fetchRows
 * @param {string} [status] - статус получения данных (SUCCESS/ERROR)
 * @param {Object} [payload] - полезная нагрузка (данные)
 * @returns {Object} экшен для загрузки данных таблички
 */

export const fetchRows = makeActionCreator(FETCH_ROWS)
