import React, {Component} from 'react'

import {CardsTableContainer} from 'containers'

import {Icon} from 'components/utility'

import style from './styles/Cards.module.scss'

class Cards extends Component {
  constructor(props) {
    super(props)

    this.state = {
      vwidth: 0,
      startWidth: 0,
      width: 70,
      startX: 0,
      isMoving: false
    }
  }

  handleStart = event => {
    event.preventDefault()
    const vwidth = window.innerWidth
    this.setState({
      isMoving: true,
      startWidth: this.state.width,
      vwidth,
      startX: event.pageX
    })
  }

  handleMove = event => {
    const {isMoving, vwidth, startWidth, startX} = this.state
    const {pageX} = event
    const width = Math.max(Math.min(startWidth + ((pageX - startX) / vwidth) * 100, 70), 30)
    if (isMoving) {
      this.setState({width})
    }
  }

  handleEnd = event => {
    const {isMoving} = this.state

    if (isMoving) {
      this.setState({isMoving: false})
    }
  }

  render() {
    const {width} = this.state
    return (
      <div
        className={style.normal}
        onMouseMove={this.handleMove}
        onMouseUp={this.handleEnd}
        onMouseLeave={this.handleEnd}
      >
        <div
          className={style.table}
          style={{width: `${width}%`}}
        >
          <CardsTableContainer />
        </div>
        <div className={style.cards}>
          <button className={style.resize} onMouseDown={this.handleStart}>
            <Icon icon="arrow-shift-left" className={style.resizeLeft} />
            <Icon icon="arrow-shift-right" />
          </button>
        </div>
      </div>
    )
  }
}

export default Cards
