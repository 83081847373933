import React, {Component} from 'react'

import {Icon} from 'components/utility'

import style from './styles/Start.module.scss'

class Start extends Component {
  render() {
    return (
      <div className={style.normal}>
        <div className={style.advice}>
          <div className={style.label}>
            <Icon className={style.labelIcon} icon="conversation" />
            <p className={style.labelText}>Совет дня</p>
          </div>
          <p className={style.adviceText}>Многие маркетологи не рассматривают бренд в привязке к эффективности рекламных кампаний в интернете. Однако именно такое сочетание может стать ключевым для эффективного масло яблоко метро трамвай, вот такой вот каравай.</p>
        </div>
      </div>
    )
  }
}

export default Start
