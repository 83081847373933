import React, {Component} from 'react'

import style from './styles/CellData.module.scss'

class CellData extends Component {
  handleClick = (event) => {
    const {row, onAction} = this.props
    const cid = event.target.getAttribute('data-column')
    if (typeof onAction === 'function') {
      onAction('popup', {id: row.id, cid})
    }
  }

  render() {
    const {column, row} = this.props
    const data = column.id === 'ROI' ? row.attributes[column.id] - 100 : row.attributes[column.id]
    const roundedData = Math.round(data * 100) / 100
    return (
      <td className={style.normal}>
        <button
          data-column={column.id}
          className={style.button}
          onClick={this.handleClick}
        >{roundedData} <span className={style.dimension}>{column.attributes.dimension}</span></button>
      </td>
    )
  }
}

export default CellData
