import {connect} from 'react-redux'

import {Login} from 'components/auth'

const mapDispatchToProps = (dispatch) => ({

  onSubmit(user) {
    console.log(user)
  },

  onForgotPass() {
    console.log('forgot pass')
  }
})

export default connect(
  null,
  mapDispatchToProps
)(Login)
