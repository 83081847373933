import React from 'react'
import {Draggable} from 'react-beautiful-dnd'
import style from './styles/HierarchyItem.module.scss'

import {Icon, Checkbox} from 'components/utility'

const HierarchyItem = ({id, status, title, index, onToggle}) => (
  <Draggable draggableId={id} index={index}>
    {(provided) => (
      <li
        className={style.li}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <Icon icon="more-vert" className={style.icon} />
        <p className={style.title}>{title}</p>
        <Checkbox id={id} isActive={status} onToggle={onToggle} />
      </li>
    )}
  </Draggable>
)

export default HierarchyItem
