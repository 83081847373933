import React, {Component} from 'react'

import {ColumnsContainer, HierarchyContainer, SearchContainer} from 'containers'
import {Table, Footer} from '../'

import style from './styles/TableWrap.module.scss'

class TableWrap extends Component {
  render() {
    return (
      <div className={style.normal}>
        <SearchContainer />
        <ColumnsContainer />
        <Table />
        <HierarchyContainer />
        <Footer />
      </div>
    )
  }
}

export default TableWrap
