import React from 'react'
import {Link} from "react-router-dom"

import style from './styles/NotFound.module.scss'

const NotFound = () => (
  <div className={style.normal}>
    <h3 className={style.title}>404</h3>
    <p className={style.text}>Страница не найдена</p>
    <Link className={style.link} to="/">На главную страницу</Link>
  </div>
)

export default NotFound
