import React, {Component} from 'react'

import {Icon} from 'components/utility'

import style from './styles/Pagination.module.scss'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

const range = (from, to, step = 1) => {
	let i = from
	const range = []

	while(i <= to) {
		range.push(i)
		i += step
	}

	return range
}

class Pagination extends Component {
	gotoPage = page => {
    const { onChange } = this.props

		if(typeof onChange === 'function') {
			onChange(page)
		}
  }

	fetchPageNumbers = () => {

		const { totalPages, pageNeighbours, currentPage } = this.props

    const totalNumbers = (pageNeighbours * 2) + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {

      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)

      let pages = range(startPage, endPage)

      const hasLeftSpill = startPage > 2
      const hasRightSpill = (totalPages - endPage) > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }

        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, RIGHT_PAGE]
          break
        }

        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }

      return [1, ...pages, totalPages]

    }

    return range(1, totalPages)
  }

	handleClick = page => event => {
		event.preventDefault()
		this.gotoPage(page)
	}

	changePage = event => {
		const page = parseInt(event.target.value)
		this.gotoPage(page)
	}

	handleMoveLeft = event => {
		event.preventDefault()
		const {pageNeighbours, currentPage} = this.props
    this.gotoPage(currentPage - (pageNeighbours * 2) - 1)
  }

  handleMoveRight = event => {
    event.preventDefault()
		const {pageNeighbours, currentPage} = this.props
    this.gotoPage(currentPage + (pageNeighbours * 2) + 1)
  }

	changeLimit = event => {
		const {onLimit} = this.props

		if (typeof onLimit === 'function') {
			onLimit(event.target.value)
		}
	}

	render() {
		const {totalPages, currentPage, limit} = this.props

		if (totalPages === 1) return null

    const pages = this.fetchPageNumbers()

		return (
			<div className={style.normal}>
				<div className={style.onPage}>
					<span className={style.span}>Показывать:</span>
					<div className={style.selectWrap}>
						<select className={style.select} value={limit} onChange={this.changeLimit}>
							<option>15</option>
							<option value="30">30</option>
							<option value="50">50</option>
							<option value="75">75</option>
							<option value="100">100</option>
						</select>
						<Icon className={style.selectArrow} icon="arrow-drop-down" />
					</div>
				</div>
				<div className={style.toPage}>
					<ul className={style.list}>
						{pages.map((page, index) => {
							if (page === LEFT_PAGE) return (
								<li key={index} className={style.li}>
									<button className={style.button} onClick={this.handleMoveLeft}><Icon className={style.pagIcon} icon="chevron-left" /></button>
								</li>
							)

							if (page === RIGHT_PAGE) return (
								<li key={index} className={style.li}>
									<button className={style.button} onClick={this.handleMoveRight}><Icon className={style.pagIcon} icon="chevron-right" /></button>
								</li>
							)

							return (
								<li key={index} className={style.li}>
									<button
										className={ currentPage === page ? style.active : style.button}
										onClick={ this.handleClick(page) }
										>{ page }</button>
									</li>
								)
							})}
						</ul>
						<div className={style.setPage}>
							<span className={style.span}>Перейти к:</span>
							<div className={style.selectWrap}>
								<select className={style.select} value={currentPage} onChange={this.changePage}>
									{range(1, totalPages).map((p, i) => (
										<option key={p} value={p}>{p}</option>
									))}
								</select>
								<Icon className={style.selectArrow} icon="arrow-drop-down" />
							</div>
						</div>
					</div>
			</div>
		)
	}
}

export default Pagination
