export default {
  api: {
    host: 'http://140.82.39.71:8080',
    endpoints: {
      auth: {
        login: '/api/api-token-auth/'
      },
      metrics: {
        table: '/api/table',
        algorithms: '/api/algorithm/:id'
      }
    }
  }
}
