import React, {Component} from 'react'

import {ListContainer} from './'

import style from './styles/Filters.module.scss'

class Filters extends Component {
  render() {
    const {filters} = this.props
    return (
      <div className={style.normal}>
        <div className={style.filters}>
          {filters.map(f => <ListContainer
            key={f}
            id={f.id}
          />)}
        </div>
        <div className={style.labels}>
          <ListContainer
            type="blue"
          />
        </div>
      </div>
    )
  }
}

export default Filters
