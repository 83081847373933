import React from 'react'

import {ColumnsCheckbox} from '../'

import {columns as columnsData} from 'services/columns/columns'

import style from './styles/ColumnsGroup.module.scss'

const ColumnsGroup = ({status, group, onChange}) => (
  <li className={style.normal}>
    <p className={style.title}>{group.title} :</p>
    {group.columns.map(c => <ColumnsCheckbox
      key={c}
      name={c}
      label={columnsData[c].attributes.titleShort}
      isActive={status[c]}
      onChange={onChange}
    />)}
  </li>
)

export default ColumnsGroup
