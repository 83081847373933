import {connect} from 'react-redux'

import {Header} from 'components/header'

const mapStateToProps = (state) => ({
  isAuth: false,
  title: 'welcome'
})

export default connect(
  mapStateToProps,
  null
)(Header)
