import React, {Component} from 'react'

import {clientSource} from 'data/analytics'

import style from './styles/ChangeClient.module.scss'

class ChangeClient extends Component {
  handleChange = event => {
    const {onChange} = this.props
    const {value} = event.target
    if (typeof onChange === 'function') {
      onChange('client', value)
    }
  }

  render() {
    const {client} = this.props
    return (
      <select className={style.normal} value={client} onChange={this.handleChange}>
        {clientSource.map(client => (
          <option key={client.value} value={client.value}>{client.title}</option>
        ))}
      </select>
    )
  }
}

export default ChangeClient
