import {connect} from 'react-redux'
import {DateDropdown} from 'components/analytics'

import {getFilterValue} from 'services/app/selectors'
import {changeFilter} from 'services/filters/actions'

const mapStateToProps = (state) => ({
  date: getFilterValue(state, 'date')
})

const mapDispatchToProps = (dispatch) => ({
  onRange(value) {
    dispatch(changeFilter('date', value))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DateDropdown)
