import {connect} from 'react-redux'

import {TableSearch} from 'components/analytics'

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
  onSubmit(value) {
    console.log(value)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableSearch)
