import {FETCH_ROWS, TOGGLE_ROW, CHANGE_PAGE, CHANGE_LIMIT, fetchRows} from './actions'
import {CHANGE_ORDER, TOGGLE_LEVEL} from '../hierarchy/actions'
import {SUCCESS} from '../utils/actions'
import {CHANGE as CHANGE_FILTER} from 'services/filters/actions'
import {CHANGE as CHANGE_PARAM} from 'services/params/actions'
import {LOGIN} from 'services/auth/actions'
import {TOGGLE as TOGGLE_SORTING} from 'services/sorting/actions'
import {map, filter, mergeMap, takeUntil, withLatestFrom} from 'rxjs/operators'
import {
  getHierarchy, getMetrics, getPaginationInfo,
  getDateFromTo, getClientName, getAuthToken,
  getSortingParams,
} from '../app/selectors'
import * as api from './api'

const isFetchMetricsTrigger = ({type, status, payload}) => (
  (type === FETCH_ROWS && status === undefined)
  || type === TOGGLE_ROW
  || type === CHANGE_ORDER
  || type === TOGGLE_LEVEL
  || type === CHANGE_PAGE
  || type === CHANGE_LIMIT
  || type === CHANGE_FILTER
  || (type === CHANGE_PARAM && payload.name === 'client')
  || (type === LOGIN && status === SUCCESS)
  || type === TOGGLE_SORTING
)

const withoutFakeToggle = (query) => (
  query.isFullUpdate || query.tree.length > 0
)

const needFullUpdate = ({type}) => (
  type === FETCH_ROWS || type === CHANGE_ORDER
  || type === TOGGLE_LEVEL
  || type === CHANGE_PAGE
  || type === CHANGE_LIMIT
  || type === CHANGE_FILTER
  || type === CHANGE_PARAM
  || type === LOGIN
  || type === TOGGLE_SORTING
)

const prepareQuery = (action, state) => {
  const isFullUpdate = needFullUpdate(action)
  const pagination = getPaginationInfo(state)
  const client = getClientName(state)
  const metrics = getMetrics(state, action.payload)
  const tree = action.type === TOGGLE_ROW && metrics.meta.isOpen === true
    ? [metrics.meta.hierarchy]
    : []
  const date = getDateFromTo(state)
  const parent = action.type === TOGGLE_ROW ? action.payload : null
  const sorting = getSortingParams(state)
  return {
    hierarchy: getHierarchy(state),
    isFullUpdate, tree, ...pagination, client, ...date,
    token: getAuthToken(state), parent, sorting
  }
}

export function fetchMetricsEpic(action$, state$) {
  return action$.pipe(
    filter(isFetchMetricsTrigger),
    withLatestFrom(state$, prepareQuery),
    filter(withoutFakeToggle),
    mergeMap(query => api.fetchMetrics(query).pipe(
      map(response => fetchRows(SUCCESS, response)),
      takeUntil(action$.pipe(filter(isFetchMetricsTrigger)))
    ))
  )
}
