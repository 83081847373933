import {TOGGLE} from './actions'

const initialState = {
  sortBy: 'CPC',
  isAscending: false,
}

function sortingReducer(state = initialState, action) {
  if (action.type === TOGGLE) {
    return {
      sortBy: action.payload,
      isAscending: action.payload === state.sortBy ? !state.isAscending : false
    }
  }
  return state
}

export default sortingReducer
