import {connect} from 'react-redux'

import {FiltersGroup} from 'components/analytics'

const mapStateToProps = (state, {id}) => ({
  title: 'по региону',
  icon: 'placeholder'
})

const mapDispatchToProps = (dispatch) => ({
  onChange(name, value) {
    console.log(name, value)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersGroup)
