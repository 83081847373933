import React, {Component} from 'react'

import style from './styles/ProjectsRow.module.scss'

class ProjectsRow extends Component {
  render() {
    const {type = 'normal', name, list, data, active, onClick} = this.props
    return (
      <div className={style[type]}>
        <ul className={style.projectsList}>
          {list.map(i => (
            <li key={i} className={style.projectsItem}>
              <button
                data-id={i}
                data-name={name}
                className={i === active ? style.active : style.projectsButton}
                onClick={onClick}
              >{data[i].title}</button>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default ProjectsRow
