import React, {Component} from 'react'

import {Icon} from 'components/utility'

import style from './styles/ListItem.module.scss'

class ListItem extends Component {
  render() {
    const {id, title, params, type, onDelete} = this.props
    return (
      <li className={style[type]}>
        <button
          data-id={id}
          className={style.delete}
          onClick={onDelete}
        >
          <Icon className={style.iconDelete} icon="close-big" />
        </button>
        <p className={style.title}>{title}</p>
        <ul className={style.info}>
          {params.map(p => <li key={p.id} className={style.infoItem}>
            <Icon className={style.iconInfo} icon={p.icon} />
            {p.value}
          </li>)}
        </ul>
      </li>
    )
  }
}

export default ListItem
