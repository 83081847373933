import React from 'react'

import {THeadContainer, TBodyContainer} from 'containers'

import style from './styles/Table.module.scss'

const Table = ()  =><div className={style.scrollable}>
  <table className={style.table}>
    <THeadContainer />
    <TBodyContainer />
  </table>
</div>

export default Table
