export const dateRangeValues = [
  {
    title: 'Сегодня',
    value: () => {
      const today = new Date()
      today.setHours(0,0,0,0)
      return {
        from: today,
        to: today
      }
    }
  },
  {
    title: 'Вчера',
    value: () => {
      const today = new Date()
      today.setHours(0,0,0,0)
      const currentDay = today.getDate()
      const yesterday = new Date()
      yesterday.setHours(0,0,0,0)
      yesterday.setDate(currentDay - 1)
      return {
        from: yesterday,
        to: yesterday
      }
    }
  },
  {
    title: 'Неделя',
    value: () => {
      const today = new Date()
      today.setHours(0,0,0,0)
      const currentDay = today.getDate()
      const week = new Date()
      week.setHours(0,0,0,0)
      week.setDate(currentDay - 6)
      return {
        from: week,
        to: today
      }
    }
  },
  {
    title: 'Месяц',
    value: () => {
      const today = new Date()
      today.setHours(0,0,0,0)
      const currentMonth = today.getMonth()
      const month = new Date()
      month.setHours(0,0,0,0)
      month.setMonth(currentMonth - 1)
      return {
        from: month,
        to: today
      }
    }
  },
  {
    title: 'Квартал',
    value: () => {
      const today = new Date()
      today.setHours(0,0,0,0)
      const currentMonth = today.getMonth()
      const quarter = new Date()
      quarter.setHours(0,0,0,0)
      quarter.setMonth(currentMonth - 3)
      return {
        from: quarter,
        to: today
      }
    }
  },
  {
    title: 'Год',
    value: () => {
      const today = new Date()
      today.setHours(0,0,0,0)
      const currentYear = today.getFullYear()
      const year = new Date()
      year.setHours(0,0,0,0)
      year.setYear(currentYear - 1)
      return {
        from: year,
        to: today
      }
    }
  }
]

export const monthTitles = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']
