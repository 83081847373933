import {normalize} from '../utils/entities'
import immutable from 'object-path-immutable'

function itemId(item) {
  return item.id
}

function onlyTopLevel(item) {
  return item.meta.hierarchy.length === 1
}

function onlyTopLevelMetrics(metrics) {
  return metrics.filter(onlyTopLevel).map(itemId)
}

function extractChildren(response) {
  let isEmpty = true
  if (response.data.length === 0 && response.parent !== null) {
    return {
      metrics: {
        [response.parent]: {
          meta: {
            isFetching: false,
            isEmpty: true
          }
        }
      }
    }
  }
  const metrics = response.data.reduce((result, item) => {
    const {parent} = item.relationships
    if (parent) {
      if (result[parent]) {
        const {children} = result[parent].relationships
        result[parent].relationships.children.push(item.id)
        return result
      }
      result[parent] = {
        meta: {
          isFetching: false
        },
        relationships: {
          children: [item.id]
        }
      }
      isEmpty = false
      return result
    }
    return result
  }, {})
  return isEmpty ? null : {metrics}
}

export function normalizeMetrics(response) {
  const includedData = normalize(response.included)
  const mainData = normalize(response.data)
  const rowsData = response.meta.isFullUpdate ? {
    rows: onlyTopLevelMetrics(response.data)
  } : null
  const baseData = {...includedData, ...mainData, ...rowsData}
  return immutable.merge(baseData, [], extractChildren(response))
}
