import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './styles/index.scss'
import {AppContainer} from './containers'
import * as serviceWorker from './serviceWorker'

import {store} from './services/app'

const Analytics = () => (
  <Provider store={store} >
    <AppContainer />
  </Provider>
)

ReactDOM.render(<Analytics />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
