import {connect} from 'react-redux'

import {TBody} from 'components/analytics'

import {
  getTableRows, getActiveColumns, getHierarchy, getAllParams
} from 'services/app/selectors'
import {login} from 'services/auth/actions'

import {fetchAlgorithms} from 'services/metrics/api'

const mapStateToProps = (state) => ({
  rows: getTableRows(state),
  columns: getActiveColumns(state),
  hierarchy: getHierarchy(state),
  params: getAllParams(state)
})

const mapDispatchToProps = (dispatch) =>({
  onReady() {
    dispatch(login('testuser', '27wGIesV'))
    fetchAlgorithms(
      {
        date_from: '2019-10-01',
        date_to: '2019-11-05',
        client: 'games',
        target: 'campaigns',
        id: '45864567'
      },
      '9bc3e8ed100cd76b2c635488dd3088ef83899c95'
    ).subscribe(v => console.log(v))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TBody)
