import React, {Component, Fragment} from 'react'

import {
  UserContainer, UserInfoContainer,
  ProjectsContainer
} from 'containers'

import style from './styles/Header.module.scss'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      projects: false,
      user: false
    }
  }

  handleClick = name => {
    this.setState(prev => ({[name]: !prev[name]}))
    console.log(name)
  }

  render() {
    const {title = '', isAuth} = this.props
    const {user} = this.state
    return (
      <div className={style.normal}>
        <div className={style.header}>
          <h2 className={style.title}>
            <img className={style.logo} src="/assets/img/logo.png" alt="z1" />
            <span className={style.titleSpan}>{title}</span>
          </h2>
          {isAuth && <UserContainer onUser={this.handleClick} />}
        </div>
        {isAuth && <Fragment>
          <ProjectsContainer />
          {user && <UserInfoContainer />}
        </Fragment>}
      </div>
    )
  }
}

export default Header
