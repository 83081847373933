import React, {Component} from 'react'

import {FiltersGroupContainer} from '../'
import {Icon} from 'components/utility'

import style from './styles/Filters.module.scss'

class Filters extends Component {
  handleLoad = event => {
    event.preventDefault()
    const {onLoad} = this.props

    if (typeof onLoad === 'function') {
      onLoad()
    }
  }

  handleSave = event => {
    event.preventDefault()
    const {onSave} = this.props

    if (typeof onSave === 'function') {
      onSave()
    }
  }

  handleReset = event => {
    event.preventDefault()
    const {onReset} = this.props

    if (typeof onReset === 'function') {
      onReset()
    }
  }

  handleClose = event => {
    event.preventDefault()
    const {onClose} = this.props

    if (typeof onClose === 'function') {
      onClose()
    }
  }

  render() {
    const {isOpen} = this.props
    return (
      <div className={isOpen ? style.opened : style.normal}>
        <ul className={style.header}>
          <li className={style.headerItem}>
            <button className={style.headerButton} onClick={this.handleLoad}>
              <Icon className={style.headerIcon} icon="upload-cloud" />
              <span className={style.headerText}>Загрузить</span>
            </button>
          </li>
          <li className={style.headerItem}>
            <button className={style.headerButton} onClick={this.handleSave}>
              <Icon className={style.headerIcon} icon="download-cloud" />
              <span className={style.headerText}>Сохранить</span>
            </button>
          </li>
        </ul>
        <button className={style.reset} onClick={this.handleReset}>
          <Icon className={style.resetIcon} icon="close-big" />сбросить все фильтры
        </button>
        <ul className={style.filters}>
          <li className={style.filtersItem}>
            <FiltersGroupContainer id="id" />
          </li>
        </ul>
        <button className={style.close} onClick={this.handleClose}>
          Закрыть
          <Icon className={style.closeIcon} icon="close-big" />
        </button>
      </div>
    )
  }
}

export default Filters
