import React from 'react'
import {Link} from "react-router-dom"

import {Auth} from 'components/auth'

import style from './styles/Authorization.module.scss'

const Authorization = () => (
  <div className={style.normal}>
    <div className={style.left}></div>
    <div className={style.right}>
      <Auth />
      <div className={style.authFooter}>
        <ul className={style.links}>
          <li className={style.linksItem}>
            <Link className={style.authFooterLink} to="/help">Помощь</Link>
          </li>
          <li className={style.linksItem}>
            <Link className={style.authFooterLink} to="/contacts">Контакты</Link>
          </li>
          <li className={style.linksItem}>
            <Link className={style.authFooterLink} to="/confidentiality">Условия пользования</Link>
          </li>
        </ul>
        <small className={style.copyright}>все права защищены &copy; 2019</small>
      </div>
    </div>
  </div>
)

export default Authorization
