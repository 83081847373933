import {combineReducers} from 'redux'
import hierarchy from '../hierarchy'
import metrics from '../metrics'
import columns from '../columns'
import auth from '../auth'
import filters from '../filters'
import params from '../params'
import sorting from '../sorting'

export default combineReducers({
  auth, metrics, columns, hierarchy,
  filters, params, sorting,
})
