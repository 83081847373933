import React, {Component} from 'react'

import {TableRowContainer} from './'

import style from './styles/Table.module.scss'

class Table extends Component {
  render() {
    const {rows} = this.props
    return (
      <div className={style.normal}>
        <table className={style.table}>
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.map(r => <TableRowContainer id={r} />)}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Table
