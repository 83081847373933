import React, {Component} from 'react'

import {Icon} from 'components/utility'
import {DateRangeContainer, TableParamsContainer,
DateDropdownContainer} from 'containers'

import style from './styles/Header.module.scss'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      containerWidth: 0,
      tapeWidth: 0,
      isMore: false,
      isMoving: false,
      offset: 0,
      startOffset: 0,
      startX: 0,
      isPopupOpen: false
    }
  }

  saveContainer = ref => this.container = ref
  saveTape = ref => this.tape = ref

  componentDidMount() {
    const containerWidth = this.container.clientWidth
    const tapeWidth = this.tape.children[0].clientWidth + this.tape.children[1].clientWidth + 16
    const isMore = tapeWidth > containerWidth
    this.setState({
      containerWidth,
      tapeWidth,
      isMore
    })
  }

  handleStart = event => {
    const {pageX} = event
    const {offset} = this.state
    if (event.target === this.tape) {
      const containerWidth = this.container.clientWidth
      const tapeWidth = this.tape.children[0].clientWidth + this.tape.children[1].clientWidth + 16
      this.setState({isMoving: true, startOffset: offset, startX: pageX, tapeWidth, containerWidth})
    }
  }

  handleMove = event => {
    event.preventDefault()
    const {pageX} = event
    const {containerWidth, tapeWidth, isMoving, isMore, startOffset, startX} = this.state

    if (isMoving && isMore) {
      const limit = containerWidth - tapeWidth
      const offset = Math.max(Math.min(startOffset + pageX - startX, 0), limit)
      this.setState({offset})
    }
  }

  handleEnd = event => {
    event.preventDefault()

    this.setState({isMoving: false})
  }

  handleDateOpen = (isPopupOpen) => {
    this.setState({isPopupOpen})
  }

  render() {
    const {isMore, offset, isPopupOpen} = this.state

    return (
      <div className={style.normal}>
        <DateDropdownContainer isOpen={isPopupOpen} onToggle={this.handleDateOpen} />
        <div
          ref={this.saveContainer}
          className={isMore ? style.draggable : style.container}
          onMouseDown={this.handleStart}
          onMouseMove={this.handleMove}
          onMouseUp={this.handleEnd}
          onMouseLeave={this.handleEnd}
        >
          <div
            ref={this.saveTape}
            className={style.tape}
            style={{transform: `translateX(${offset}px)`}}
          >
            <div className={style.date}>
              <DateRangeContainer isOpen={isPopupOpen} onToggle={this.handleDateOpen} />
              {isMore && <button className={style.drag}>
                <Icon icon="arrow-shift-left" className={style.dargLeft} />
                <Icon icon="arrow-shift-right" />
              </button>}
            </div>
            <TableParamsContainer />
          </div>
        </div>
      </div>
    )
  }
}

export default Header
