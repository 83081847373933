import React, {Component} from 'react'

import style from './styles/CellName.module.scss'

class CellName extends Component {
  handleClick = event => {
    event.preventDefault()
    const {row, onAction} = this.props
    if (typeof onAction === 'function') {
      onAction('toggle', row.id)
    }
  }

  getStatusClass() {
    const {isFetching, isOpen} = this.props.row.meta
    return isFetching
      ? style.loading
      : isOpen
        ? style.active
        : style.toggle
  }

  render() {
    const {source, row, hierarchy} = this.props
    const level = row.meta.hierarchy.length - 1
    const isEnd = hierarchy.length === level + 1
    const {isEmpty} = row.meta
    return (
      <td className={style.normal} style={{paddingLeft: `${level * 20 + 10}px`}}>
        {!isEnd && !isEmpty ? <button
          className={this.getStatusClass()}
          onClick={this.handleClick}
        ></button>
        : <span className={style.emptySpan}></span>}[{source.id}] {source.attributes.title}
      </td>
    )
  }
}

export default CellName
