import React, {Component} from 'react'

import {AnalyticsFiltersContainer, AnalyticsActiveFiltersContainer} from 'containers'
import {Header, TableWrap} from './'

import style from './styles/Analytics.module.scss'

class Analytics extends Component {
  render() {
    return (
      <div className={style.normal}>
        <Header />
        <AnalyticsActiveFiltersContainer />
        <TableWrap />
        <AnalyticsFiltersContainer />
      </div>
    )
  }
}

export default Analytics
