export const pages = {
  left: [
    {
      title: 'Фильтры',
      icon: 'checklist',
      link: '/filters',
    },
    {
      title: 'Карточки',
      icon: 'library',
      link: '/cards',
    },
    {
      title: 'Отзывы',
      icon: 'comments',
      link: '/testimonials',
    },
    {
      title: 'Правила',
      icon: 'notes',
      link: '/rules',
    },
  ],
  right: [
    {
      title: 'Charts',
      icon: 'equalizer',
      link: '/charts',
    },
    {
      title: 'Analitycs',
      icon: 'tune',
      link: '/analytics',
    },
    {
      title: 'Timeline',
      icon: 'share',
      link: '/timeline',
    },
  ]
}

export const payment = [
  'mastercard', 'visa'
]
