import {connect} from 'react-redux'

import {getClientName} from 'services/app/selectors'
import {changeParam} from 'services/params/actions'

import {ChangeClient} from 'components/analytics'

const mapStateToProps = (state, {align}) => ({
  client: getClientName(state)
})

const mapDispatchToProps = (dispatch) => ({
  onChange(name, value) {
    dispatch(changeParam(name, value))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeClient)
