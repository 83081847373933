import {makeActionCreator} from '../utils/actions'

export const CHANGE_ORDER = 'hierarchy/changeOrder'
export const TOGGLE_LEVEL = 'hierarchy/toggleLevel'

/**
 * @function changeOrder
 * @param {String[]} newOrder - новый порядок уровней. ВАЖНО: перечислять
 * нужно как активные уровни, так и уровни которые отключены
 * @returns {Object} экшен для изменения порядка иерархии
 * @example changeOrder(['campaigns', 'groups', 'ads'])
 */

export const changeOrder = makeActionCreator(CHANGE_ORDER)

/**
 * @function toggleLevel
 * @param {String} levelId - идентификатор уровня, который тогглим
 * @returns {Object} экшен для изменения состояния уровня
 * @example toggleLevel('sources')
 */

export const toggleLevel = makeActionCreator(TOGGLE_LEVEL)
