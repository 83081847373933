import {connect} from 'react-redux'

import App from 'components/app'

const mapStateToProps = (state) => ({
  isProjectSelected: true
})

export default connect(
  mapStateToProps,
  null
)(App)
