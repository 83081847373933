import React, {Component} from 'react'

import {ListItem} from './'
import {Icon} from 'components/utility'

import style from './styles/List.module.scss'

class List extends Component {
  constructor(props) {
    super(props)

    this.state = {
      label: '',
      isAdding: false
    }
  }

  handleAdd = event => {
    event.preventDefault()

    this.setState({isAdding: true})
  }

  handleChange = event => {
    const {name, value} = event.target
    this.setState({[name]: value})
  }

  handleSave = event => {
    event.preventDefault()
  }

  handleDelete = event => {
    event.preventDefault()
  }

  render() {
    const {type = 'normal', title, list} = this.props
    const {label, isAdding} = this.state
    return (
      <div className={style[type]}>
        <h4 className={style.title}>{title}</h4>
        <ul className={style.list}>
          {list.map(i => (
            <ListItem
              key={i.id}
              id={i.id}
              title={i.title}
              params={i.params}
              type={type}
              onDelete={this.handleDelete}
            />
          ))}
          {isAdding && <li className={style.listItem}>
            <input
              className={style.input}
              name="label"
              type="text"
              value={label}
              placeholder="Заголовок"
              onChange={this.handleChange}
            />
            <button className={style.save} onClick={this.handleSave}></button>
          </li>}
        </ul>
        <button className={style.add} onClick={this.handleAdd}><Icon icon="add" /></button>
      </div>
    )
  }
}

export default List
