import React, {Component} from 'react'

import {Icon} from 'components/utility'

import style from './styles/ActiveFilters.module.scss'

class ActiveFilters extends Component {
  handleOpen = event => {
    event.preventDefault()
    const {onOpen} = this.props

    if (typeof onOpen === 'function') {
      onOpen()
    }
  }

  handleDisable = event => {
    event.preventDefault()
    const {onRemove} = this.props
    const name = event.target.getAttribute('data-name')
    const value = event.target.getAttribute('data-value')

    if (typeof onRemove === 'function') {
      onRemove(name, value)
    }
  }

  render() {
    return (
      <div className={style.normal}>
        <button className={style.open} onClick={this.handleOpen}><Icon icon="add" /></button>
        <button
          className={style.filter}
          data-name="name"
          data-value="value"
          onClick={this.handleDisable}
        >
          <span className={style.filterTitle}>title</span>
          <Icon className={style.filterIcon} icon="close-big" />
        </button>
      </div>
    )
  }
}

export default ActiveFilters
