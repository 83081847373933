import React, {Component} from 'react'
import {Route, Switch} from "react-router-dom"

import {Cards} from 'components/cards'
import {Analytics} from 'components/analytics'
import {Authorization, Start, NotFound} from './'
import {FiltersPageContainer} from 'containers'

import style from './styles/Main.module.scss'

class Main extends Component {
  renderStart() {
    const {isAuth} = this.props
    return !isAuth
      ? <Route path="/" exact component={Authorization} />
      : <Route path="/" exact component={Start} />
  }

  render() {
    return (
      <div className={style.normal}>
        <Switch>
          {this.renderStart()}
          <Route path="/filters" component={FiltersPageContainer} />
          <Route path="/cards" component={Cards} />
          <Route path="/analytics" component={Analytics} />
          <Route component={NotFound} />
        </Switch>
      </div>
    )
  }
}

export default Main
