import {connect} from 'react-redux'

import {Filters} from 'components/filters'

const mapStateToProps = (state) => ({
  filters: ['id1', 'id42']
})

export default connect(
  mapStateToProps,
  null
)(Filters)
