const fMon = v => v < 9 ? '0' + (v + 1) : (v + 1)
const fDay = v => v < 10 ? '0' + v : v

function encodeDate(date) {
  return date.getFullYear() + '-' + fMon(date.getMonth()) + '-' + fDay(date.getDate())
}

export function getDateFromTo(state) {
  const {from, to} = state.date
  return {
    date_from: encodeDate(from),
    date_to: encodeDate(to)
  }
}

export function getFilterValue(state, name) {
  return state[name] || null
}
