import React, {Component} from 'react'

import {ProjectsRow} from './'

import style from './styles/Projects.module.scss'

class Projects extends Component {
  constructor(props) {
    super(props)

    this.state = {
      category: null,
      project: null,
      product: null,
    }
  }

  handleClick = event => {
    event.preventDefault()
    const name = event.target.getAttribute('data-name')
    const id = event.target.getAttribute('data-id')
    const newState = name === 'category'
      ? {
        ...this.state,
        category: id,
        project: null,
        product: null
      }
      : {[name]: id}

    this.setState(newState)
  }

  render() {
    const {data} = this.props
    const {category, project, product} = this.state
    const {categoriesList, categories, projects, products} = data

    return (
      <div className={style.normal}>
        <div className={style.rows}>
          <ProjectsRow
            name="category"
            list={categoriesList}
            data={categories}
            active={category}
            onClick={this.handleClick}
          />
          {category && <ProjectsRow
            name="project"
            list={categories[category].projects}
            data={projects}
            active={project}
            onClick={this.handleClick}
          />}
        </div>
        {project && <ProjectsRow
          type="products"
          name="product"
          list={projects[project].products}
          data={products}
          active={product}
          onClick={this.handleClick}
        />}
      </div>
    )
  }
}

export default Projects
