/**
 * Возвращает метрику с заданным id
 * @param {Object} state - состояние компонента services/metrics
 * @param {string} id - идентификатор искомой метрики
 * @returns {Object|null} экземпляр искомой метрики, либо "null",
 * в случае, если она не найдена
 */

export function getMetrics(state, id) {
  return state.metrics[id] || null
}

/**
 * Возвращает источник данных для метрики (группы, кампании и т.д.)
 * @param {Object} state - состояние компонента services/metrics
 * @param {string} id - идентификатор метрики для которой запрашивается источник
 * @returns {Object|null} экземпляр источника, либо "null"
 */

export function getMetricsSource(state, id) {
  const {source} = state.metrics[id].relationships
  if (source === undefined) {
    return null
  }
  return state[source.type] && state[source.type][source.id]
    ? state[source.type][source.id]
    : null
}

/**
 * Возвращает список идентификаторов строк-метрик для древовидной таблицы
 * @param {Object} state - состояние компонента services/metrics
 * @returns {string[]} список идентификаторов строк-метрик
 * для древовидной таблицы
 */

export function getMetricsTree(state) {
  function groupChildren(result, id) {
    return result.concat(itemWithChildrenMap(id))
  }

  function itemWithChildrenMap(id) {
    const {meta, relationships} = state.metrics[id]
    if (relationships.children && meta.isOpen === true) {
      const children = relationships.children.reduce(groupChildren, [])
      return [id].concat(children)
    }
    return id
  }

  return state.rows.reduce(groupChildren, [])
}

export function getPaginationInfo(state) {
  return {
    page: state.page,
    limit: state.limit,
    total: state.total
  }
}
