import {connect} from 'react-redux'

import {Hierarchy} from 'components/analytics'

import {getHierarchyOrder, getHierarchyStatus} from 'services/app'
import {changeOrder, toggleLevel} from 'services/hierarchy/actions'

const mapStateToProps = (state) => ({
  order: getHierarchyOrder(state),
  status: getHierarchyStatus(state)
})

const mapDispatchToProps = (dispatch) => ({
  onToggle(id) {
    dispatch(toggleLevel(id))
  },

  onOrder(levels) {
    dispatch(changeOrder(levels))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Hierarchy)
