import React, {Component} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {HeaderContainer, MainContainer, FooterContainer} from 'containers'

import style from './App.module.scss'

class App extends Component {
  render() {
    const {isProjectSelected} = this.props
    return (
      <div className={style.normal}>
        <Router>
          <HeaderContainer />
          <MainContainer />
          {isProjectSelected && <FooterContainer />}
        </Router>
      </div>
    )
  }
}

export default App
