import {connect} from 'react-redux'

import {UserInfo} from 'components/header'

const stats = [
  {value: 42896, label: 'просмотров'},
  {value: 428, label: 'коррекции'},
  {value: 4.8, label: 'рейтинг'}
]

const mapStateToProps = (state) => ({
  ava: '',
  name: 'Антонина Сергеевна',
  email: 'html42@gmail.com',
  stats,
})

export default connect(
  mapStateToProps,
  null
)(UserInfo)
