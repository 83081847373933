const onlyActive = (status) => (id) => status[id]

export function getOrder(state) {
  return state.order
}

export function getStatus(state) {
  return state.status
}

export function getActiveLevels(state) {
  return state.order.filter(onlyActive(state.status))
}

export function getLevelName(state, index) {
  return getActiveLevels(state)[index] || null
}
