import {connect} from 'react-redux'

import {Pagination} from 'components/analytics'
import {getPaginationInfo} from 'services/app/selectors'
import {changePage, changeLimit} from 'services/metrics/actions'

const mapStateToProps = (state) => {
  const {page, limit, total} = getPaginationInfo(state)
  return {
    totalPages: total,
    currentPage: page,
    pageNeighbours: 2,
    total: 30,
    limit: limit
  }
}

const mapDispatchToProps = (dispatch) => ({
  onChange(newPage) {
    dispatch(changePage(newPage))
  },
  onLimit(newLimit) {
    dispatch(changeLimit(newLimit))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pagination)
