import React, {Component} from 'react'

import style from './styles/Filter.module.scss'

class Filter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search: '',
      filtered: []
    }
  }

  componentDidMount() {
    this.setState({
      filtered: this.props.values
    })
  }

  handleChange = event => {
    const {name, value} = event.target
    const {values} = this.props
    if (value !== '') {
      const filtered = values.filter(v => {
        const lower = v.toLowerCase()
        const filter = value.toLowerCase()
        return lower.includes(filter)
      })
      this.setState({
        filtered,
        [name]: value
      })
    } else {
      this.setState({
        filtered: values,
        [name]: value
      })
    }
  }

  handleClick = event => {
    event.preventDefault()
    const value = event.target.getAttribute('data-value')
    const {name, onChange} = this.props

    if (typeof onChange === 'function') {
      onChange(name, value)
    }
  }

  render() {
    const {search, filtered} = this.state
    const {isOpen} = this.props

    if (!isOpen) {return null}

    return (
      <div className={style.normal}>
        <input
          className={style.input}
          type="text"
          name="search"
          placeholder="ПОИСК"
          value={search}
          onChange={this.handleChange}
          autoComplete="off"
        />
        <ul className={style.values}>
          {filtered.map((v, i) => (
            <li
              key={i}
              data-value={v}
              className={style.valuesItem}
              onClick={this.handleClick}
            >{v}</li>
          ))}
        </ul>
      </div>
    )
  }
}

export default Filter
