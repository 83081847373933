import {connect} from 'react-redux'

import {ProjectsRow} from 'components/header'

const data = [
  {}
]

const mapStateToProps = (state, id) => ({
  data
})

export default connect(
  mapStateToProps
)(ProjectsRow)
