import React, {Component} from 'react'

import {Filter} from '../'
import {Icon} from 'components/utility'

import style from './styles/FiltersGroup.module.scss'

const values = ['Hello', 'this', 'beautiful', 'world!', 'this', 'beautiful', 'world!', 'this', 'beautiful', 'world!', 'this', 'beautiful', 'world!']

class FiltersGroup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  handleOpen = event => {
    event.preventDefault()
    this.setState(prev => ({isOpen: !prev.isOpen}))
  }

  render() {
    const {isOpen} = this.state
    const {title, icon, onChange} = this.props
    return (
      <div className={isOpen ? style.opened : style.normal}>
        <button className={style.button} onClick={this.handleOpen}>
          <Icon className={style.buttonIcon} icon={icon} />{title}
          <Icon className={style.buttonChevron} icon={isOpen ? 'chevron-up' : 'chevron-down'} />
        </button>
        <Filter isOpen={isOpen} name="name" values={values} onChange={onChange} />
      </div>
    )
  }
}

export default FiltersGroup
