import React, {Component} from 'react'

import {TRowContainer} from '../'

class TBody extends Component {
  componentDidMount() {
    const {onReady} = this.props
    if (typeof onReady === 'function') {
      onReady()
    }
  }

  render() {
    const {rows, ...other} = this.props
    return (
      <tbody>
        {rows.map(r => <TRowContainer
          key={r}
          id={r}
          {...other}
        />)}
      </tbody>
    )
  }
}

export default TBody
