import {map, filter, mergeMap} from 'rxjs/operators'
import {login, LOGIN} from './actions'
import {SUCCESS} from '../utils/actions'
import * as api from './api'

function isAuthTrigger({type, status}) {
  return type === LOGIN && status === undefined
}

export function authEpic(action$) {
  return action$.pipe(
    filter(isAuthTrigger),
    mergeMap(action => api.login(action.payload).pipe(
      map(response => login(SUCCESS, response.token || null))
    ))
  )
}
