import {connect} from 'react-redux'

import {Projects} from 'components/header'

const data = {
  categoriesList: ['c1', 'c2'],
  categories: {
    c1: {
      id: 'c1',
      title: 'Категория 1',
      name: '',
      icon: '',
      projects: ['p1', 'p2'],
    },
    c2: {
      id: 'c2',
      title: 'Категория 2',
      name: '',
      icon: '',
      projects: ['p34', 'p89'],
    },
  },
  projects: {
    p1: {
      id: 'p1',
      title: 'Проект 1',
      name: '',
      icon: '',
      products: ['s1'],
    },
    p2: {
      id: 'p2',
      title: 'Проект 2',
      name: '',
      icon: '',
      products: ['s3'],
    },
    p34: {
      id: 'p34',
      title: 'Проект 34',
      name: '',
      icon: '',
      products: ['s6', 's105'],
    },
    p89: {
      id: 'p89',
      title: 'Проект 89',
      name: '',
      icon: '',
      products: ['s4', 's24'],
    },
  },
  products: {
    s1: {
      id: 's1',
      title: 'Раздел 1',
      name: '',
      icon: '',
    },
    s2: {
      id: 's2',
      title: 'Раздел 2',
      name: '',
      icon: '',
    },
    s3: {
      id: 's3',
      title: 'Раздел 3',
      name: '',
      icon: '',
    },
    s4: {
      id: 's4',
      title: 'Раздел 4',
      name: '',
      icon: '',
    },
    s6: {
      id: 's6',
      title: 'Раздел 6',
      name: '',
      icon: '',
    },
    s24: {
      id: 's24',
      title: 'Раздел 24',
      name: '',
      icon: '',
    },
    s105: {
      id: 's105',
      title: 'Раздел 105',
      name: '',
      icon: '',
    },
  }
}

const mapStateToProps = (state) => ({
  data
})

export default connect(
  mapStateToProps,
  null
)(Projects)
