import * as hierarchy from '../hierarchy/selectors'
import * as metrics from '../metrics/selectors'
import * as columns from '../columns/selectors'
import * as filters from '../filters/selectors'
import * as params from '../params/selectors'
import * as sorting from '../sorting/selectors'

export const getHierarchyStatus = state => hierarchy.getStatus(state.hierarchy)
export const getHierarchyOrder = state => hierarchy.getOrder(state.hierarchy)
export const getHierarchy = state => hierarchy.getActiveLevels(state.hierarchy)
export const getTableRows = state => metrics.getMetricsTree(state.metrics)
export const getMetrics = (state, id) => metrics.getMetrics(state.metrics, id)
export const getPaginationInfo = (state) => metrics.getPaginationInfo(state.metrics)
export const getMetricsSource = (state, id) => metrics.getMetricsSource(state.metrics, id)
export const getActiveColumns = (state) => columns.getActiveColumns(state.columns)
export const getDateFromTo = (state) => filters.getDateFromTo(state.filters)
export const getFilterValue = (state, name) => filters.getFilterValue(state.filters, name)
export const getAllParams = (state) => params.getAllParams(state.params)
export const getClientName = (state) => params.getClientName(state.params)
export const getAuthToken = (state) => state.auth.token
export const getSortingParams = (state) => sorting.getSortingParams(state.sorting)
