import React, {Component} from 'react'

import {ChangeClientContainer} from 'containers'

import {TableParam} from '../'

import style from './styles/TableParams.module.scss'

import {tableParams} from 'data/analytics'

class TableParams extends Component {
  render() {
    const {params, onChange} = this.props

    return (
      <div className={style.normal}>
        <ChangeClientContainer />
        {tableParams.map(p => <TableParam
          key={p.name}
          name={p.name}
          title={p.title}
          values={params[p.name]}
          dimension={p.dimension}
          color={p.color}
          onChange={onChange}
        />)}
      </div>
    )
  }
}

export default TableParams
