import React, {Component} from 'react'

import {Cell} from '../'

import style from './styles/TRow.module.scss'

class TRow extends Component {
  render() {
    const {columns, ...other} = this.props
    return (
      <tr className={style.normal}>
        {columns.map(column => <Cell
          type={column.attributes.cell[1]}
          key={column.id}
          column={column}
          {...other}
        />)}
      </tr>
    )
  }
}

export default TRow
