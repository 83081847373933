import {connect} from 'react-redux'

import {Main} from 'components/page'

const mapStateToProps = (state) => ({
  isAuth: true
})

export default connect(
  mapStateToProps,
  null
)(Main)
