export const tableParams = [
  {
    name: 'clicks',
    title: 'Порог по кликам',
    dimension: 'шт.',
    color: 'normal',
  },
  {
    name: 'troi',
    title: 'ROI целевой',
    dimension: '%',
    color: 'green',
  },
  {
    name: 'tcost',
    title: 'Цена лида целевая',
    dimension: 'руб.',
    color: 'red',
  },
  {
    name: 'rate',
    title: 'Ставка ОТ и ДО',
    dimension: 'руб.',
    color: 'normal',
  },
  {
    name: 'adjustmentMax',
    title: 'Корректировка',
    dimension: '%',
    color: 'normal',
  },
]

export const clientSource = [
  {
    title: 'МФО',
    value: 'mfo',
  },
  {
    title: 'Игры',
    value: 'games',
  }
]

export const hierarchyTitles = {
  campaigns: 'Кампании', groups: 'Группы объявлений', phrases: 'Ключевые фразы',
  ads: 'Объявления', sources: 'Источники'
}
