import React, {Component} from 'react'
import {Icon} from 'components/utility'

import style from './styles/CellHeaderData.module.scss'

class CellHeaderData extends Component {
  handleClick = event => {
    event.preventDefault()
    const {column, onSort} = this.props
    if (typeof onSort === 'function') {
      onSort(column.id)
    }
  }

  render() {
    const {column, sorting} = this.props
    const {sortBy, isAscending} = sorting
    const isSorted = column.id === sortBy
    return (
      <td className={style.normal}>
        <button className={style.button} onClick={this.handleClick}>
          {column.attributes.title}
          <span className={style.sorted}>
            <Icon className={isAscending && isSorted ? style.highlight : style.sortedIcon} icon="arrow-drop-up" />
            <Icon className={!isAscending && isSorted ? style.highlight : style.sortedIcon} icon="arrow-drop-down" />
          </span>
        </button>
      </td>
    )
  }
}

export default CellHeaderData
