export const columns = {
  'name': {
    id: 'name',
    type: 'columns',
    attributes: {
      title: 'Имя [Id]',
      titleShort: '',
      cell: ['headerName', 'name']
    }
  },
  'CPC': {
    id: 'CPC',
    type: 'columns',
    attributes: {
      title: 'CPC',
      titleShort: 'CPC',
      cell: ['headerData', 'data'],
      dimension: 'руб.',
    }
  },
  'CTR': {
    id: 'CTR',
    type: 'columns',
    attributes: {
      title: 'CTR',
      titleShort: 'CTR',
      cell: ['headerData', 'data'],
      dimension: '',
    }
  },
  'ROI': {
    id: 'ROI',
    type: 'columns',
    attributes: {
      title: 'ROI',
      titleShort: '%',
      cell: ['headerData', 'data'],
      dimension: '%',
    }
  },
  'age': {
    id: 'age',
    type: 'columns',
    attributes: {
      title: 'Возраст',
      titleShort: 'лет',
      cell: ['headerData', 'data'],
      dimension: 'лет',
    }
  },
  'approved_unique': {
    id: 'approved_unique',
    type: 'columns',
    attributes: {
      title: 'Продаж. уник.',
      titleShort: 'уник.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'approved_count': {
    id: 'approved_count',
    type: 'columns',
    attributes: {
      title: 'Approved',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'approved_leads_prct': {
    id: 'approved_leads_prct',
    type: 'columns',
    attributes: {
      title: 'Approved % от ЛИД',
      titleShort: '% от ЛИД',
      cell: ['headerData', 'data'],
      dimension: '%',
    }
  },
  'approved_sum': {
    id: 'approved_sum',
    type: 'columns',
    attributes: {
      title: 'Выручка',
      titleShort: 'цена',
      cell: ['headerData', 'data'],
      dimension: 'руб.',
    }
  },
  'approved_cost': {
    id: 'approved_cost',
    type: 'columns',
    attributes: {
      title: 'Цена продажи',
      titleShort: 'стоимость',
      cell: ['headerData', 'data'],
      dimension: 'руб.',
    }
  },
  'bounces': {
    id: 'bounces',
    type: 'columns',
    attributes: {
      title: 'Отказы',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'bounces_prct': {
    id: 'bounces_prct',
    type: 'columns',
    attributes: {
      title: 'Отказы %',
      titleShort: '%',
      cell: ['headerData', 'data'],
      dimension: '%',
    }
  },
  'change_direction': {
    id: 'change_direction',
    type: 'columns',
    attributes: {
      title: 'Клики по категориям',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'change_filter': {
    id: 'change_filter',
    type: 'columns',
    attributes: {
      title: 'Клики по фильтрам',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'click_offer': {
    id: 'click_offer',
    type: 'columns',
    attributes: {
      title: 'Конверсия',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'click_unique': {
    id: 'click_unique',
    type: 'columns',
    attributes: {
      title: 'Уник. конверсия',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'click_unique_cost': {
    id: 'click_unique_cost',
    type: 'columns',
    attributes: {
      title: 'Цена уник. конверсии',
      titleShort: 'руб.',
      cell: ['headerData', 'data'],
      dimension: 'руб.',
    }
  },
  'click_unique_prct': {
    id: 'click_unique_prct',
    type: 'columns',
    attributes: {
      title: 'Уник. конверсия %',
      titleShort: '%',
      cell: ['headerData', 'data'],
      dimension: '%',
    }
  },
  'clicks': {
    id: 'clicks',
    type: 'columns',
    attributes: {
      title: 'Переходы',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'cost': {
    id: 'cost',
    type: 'columns',
    attributes: {
      title: 'Затраты всего',
      titleShort: 'руб.',
      cell: ['headerData', 'data'],
      dimension: 'руб.',
    }
  },
  'enter_landing': {
    id: 'enter_landing',
    type: 'columns',
    attributes: {
      title: 'Событие: enter_landing',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'events': {
    id: 'events',
    type: 'columns',
    attributes: {
      title: 'События',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'events_per_user': {
    id: 'events_per_user',
    type: 'columns',
    attributes: {
      title: 'События на пользователя',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'impres': {
    id: 'impres',
    type: 'columns',
    attributes: {
      title: 'Показы',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'impres_cost': {
    id: 'impres_cost',
    type: 'columns',
    attributes: {
      title: 'Цена показа',
      titleShort: 'руб.',
      cell: ['headerData', 'data'],
      dimension: 'руб.',
    }
  },
  'leads': {
    id: 'leads',
    type: 'columns',
    attributes: {
      title: 'Уник. Лид',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'leads_cost': {
    id: 'leads_cost',
    type: 'columns',
    attributes: {
      title: 'Цена лида',
      titleShort: 'цена',
      cell: ['headerData', 'data'],
      dimension: 'руб.',
    }
  },
  'leads_count_per_click_unique': {
    id: 'leads_count_per_click_unique',
    type: 'columns',
    attributes: {
      title: 'Лиды ср.',
      titleShort: 'ср.',
      cell: ['headerData', 'data'],
      dimension: '?',
    }
  },
  'leads_prct': {
    id: 'leads_prct',
    type: 'columns',
    attributes: {
      title: 'Уник. Лид % от уник. users',
      titleShort: '% от уник. users',
      cell: ['headerData', 'data'],
      dimension: '%',
    }
  },
  'leads_unique': {
    id: 'leads_unique',
    type: 'columns',
    attributes: {
      title: 'Уник. Лид',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'offer_details': {
    id: 'offer_details',
    type: 'columns',
    attributes: {
      title: 'Клики на подробнее',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'pending_count': {
    id: 'pending_count',
    type: 'columns',
    attributes: {
      title: 'Pending',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'pending_leads_prct': {
    id: 'pending_leads_prct',
    type: 'columns',
    attributes: {
      title: 'Pending % от ЛИД',
      titleShort: '% от ЛИД',
      cell: ['headerData', 'data'],
      dimension: '%',
    }
  },
  'pending_sum': {
    id: 'pending_sum',
    type: 'columns',
    attributes: {
      title: 'Цена Pending',
      titleShort: 'цена',
      cell: ['headerData', 'data'],
      dimension: 'руб.',
    }
  },
  'rejected_count': {
    id: 'rejected_count',
    type: 'columns',
    attributes: {
      title: 'Rejected',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'rejected_leads_prct': {
    id: 'rejected_leads_prct',
    type: 'columns',
    attributes: {
      title: 'Rejected % от ЛИД',
      titleShort: '% от ЛИД',
      cell: ['headerData', 'data'],
      dimension: '%',
    }
  },
  'rejected_sum': {
    id: 'rejected_sum',
    type: 'columns',
    attributes: {
      title: 'Цена Rejected',
      titleShort: 'цена',
      cell: ['headerData', 'data'],
      dimension: 'руб.',
    }
  },
  'users': {
    id: 'users',
    type: 'columns',
    attributes: {
      title: 'Users',
      titleShort: 'шт.',
      cell: ['headerData', 'data'],
      dimension: 'шт.',
    }
  },
  'adjustmentA': {
    id: 'adjustmentA',
    type: 'columns',
    attributes: {
      title: 'по ROI',
      titleShort: 'roi',
      cell: ['headerAdj', 'adj'],
      dimension: 'руб.',
      style: 'green'
    }
  },
  'adjustmentB': {
    id: 'adjustmentB',
    type: 'columns',
    attributes: {
      title: 'по ЛИД',
      titleShort: 'лид',
      cell: ['headerAdj', 'adj'],
      dimension: 'руб.',
      style: 'red'
    }
  }
}

export const columnsOrder = [
  'name',
  'impres',
  'impres_cost',
  'clicks',
  'CTR',
  'CPC',
  'bounces',
  'bounces_prct',
  'events',
  'events_per_user',
  'change_filter',
  'change_direction',
  'offer_details',
  'click_unique',
  'click_unique_prct',
  'click_unique_cost',
  'click_offer',
  'adjustmentA',
  'adjustmentB',
  'leads',
  'leads_unique',
  'leads_prct',
  'leads_count_per_click_unique',
  'leads_cost',
  'pending_count',
  'pending_sum',
  'approved_unique',
  'approved_count',
  'approved_cost',
  'approved_sum',
  'rejected_count',
  'rejected_sum',
  'cost',
  'enter_landing',
  'ROI'
]
