import React, {Component} from 'react'

import {Icon} from 'components/utility'
import {PickRange} from '../'

import style from './styles/DateRange.module.scss'

import {dateRangeValues, monthTitles} from 'data/date'


const toText = date => {
  const day = date.getDate()
  const month = monthTitles[date.getMonth()]
  const year = date.getFullYear()
  return `${day} ${month} ${year}`
}

class DateRange extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  handleOpen = event => {
    event.preventDefault()
    const {isOpen, onToggle} = this.props
    if (typeof onToggle === 'function') {
      onToggle(!isOpen)
    }
  }

  handleClick = value => {
    const {onRange} = this.props

    if (typeof onRange === 'function') {
      onRange(value)
    }
  }

  dateTransform = () => {
    const {from, to} = this.props.date
    const textFrom = toText(from)
    const textTo = toText(to)
    const text = textFrom === textTo ? textFrom : `${textFrom} - ${textTo}`
    return text
  }

  render() {
    const {date} = this.props
    const secureDate = date === null ? {from: new Date(), to: new Date()} : date
    return (
      <div className={style.normal}>
        <div className={style.current}>
          <button data-button="dateButton" className={style.currentButton} onClick={this.handleOpen}>
            <Icon className={style.currentIcon} icon="calendars" />
            <span className={style.currentDate}>{this.dateTransform()}</span>
          </button>
        </div>
        <ul className={style.list}>
          {dateRangeValues.map(r => (
            <li key={r.value} className={style.item}>
              <PickRange
                currentRange={secureDate}
                value={r.value}
                title={r.title}
                onClick={this.handleClick}
              />
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default DateRange
