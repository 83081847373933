import {connect} from 'react-redux'

import {TableRow} from './'

const row = [
  {}
]

const mapStateToProps = (state, {id}) => ({
  row
})

export default connect(
  mapStateToProps
)(TableRow)
