import {connect} from 'react-redux'

import {ActiveFilters} from 'components/analytics'

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
  onOpen() {
    console.log('open filters')
  },

  onRemove(name, value) {
    console.log(name, value)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveFilters)
