import {connect} from 'react-redux'

import {Filters} from 'components/analytics'

const mapStateToProps = (state) => ({
  isOpen: false
})

const mapDispatchToProps = (dispatch) => ({
  onLoad() {
    console.log('load filters')
  },

  onSave() {
    console.log('save filters')
  },

  onReset() {
    console.log('reset filters')
  },

  onClose() {
    console.log('close filters')
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters)
