import {connect} from 'react-redux'

import {Table} from 'components/cards'

const rows = [
  'id1', 'id3', 'id124', 'id11234'
]

const mapStateToProps = (state) => ({
   rows
})

export default connect(
  mapStateToProps
)(Table)
